/* label.custom-radio-button input[type="radio"] {
  opacity: 0;
}

label.custom-radio-button input[type="radio"] ~ .helping-el {
  background-color: #ffffff;
  border: 2px solid #1f5099;
  display: inline-block;
  border-radius: 50%;
  float: left;
  margin-right: 7px;
  padding: 11px;
  position: relative;
  top: 3px;
}

label.custom-radio-button input[type="radio"]:checked ~ .helping-el:after {
  background-color: #1f5099;
  border-radius: 50%;

  content: " ";
  height: 20px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 20px;
}
.label-text {
  flex-direction: row-reverse;
  margin-top: 100;
} */

.radio {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  margin-left: 20px;
  font-weight: bold;
}
.radio .label {
  position: relative;
  float: left;
  width: 22px;
  height: 22px;
  border: 2px solid #1f5099;
  border-radius: 100%;
}
.radio .label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #1f5099;
  transform: scale(0);
}

input[type="radio"]:checked + .label {
  border-color: #1f5099;
}
input[type="radio"]:checked + .label:after {
  transform: scale(1);
}

.hidden {
  display: none;
}
