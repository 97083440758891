html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e6e6e6 #cccccc;
  -webkit-tap-highlight-color: transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #e6e6e6;
}

*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
  border: 1px solid #cccccc;
}

/* Ensure we never use browser font's for any element */
/* Browsers have default fonts for input and other elements  */
body,
input,
select,
ion-input,
button {
  font-family: 'Open Sans', sans-serif !important;
}

/* Variable fonts are more space efficient use them when supported */
@supports (font-variation-settings: normal) {
  body,
  input,
  select,
  ion-input,
  button {
    font-family: 'Open SansVariable', sans-serif !important;
  }
}

div#root {
  width: 100%;
  height: 100%;
}

@media all and (max-width: 64em) {
  div#root.noscroll {
    overflow: hidden;
  }

  div#root.login-page {
    height: 100%;
  }
}

@media print {
  @page {
    size: A4;
    margin-top: 1.2cm;
    margin-bottom: 1.2cm;
  }

  body {
    display: block;
  }

  div#root {
    display: block;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* HTML component stying */

ion-input.standalone {
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
  border: 1px solid #cfd8dc;
  border-radius: 3px;
  text-align: left;
}

.buttonV1 {
  cursor: pointer;
  padding: 10px 30px;

  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.buttonV1.primary {
  color: white;
  background-color: #2a6dad;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.buttonV1.primary:hover {
  background-color: #3c6199;
}

.buttonV1.primary:disabled {
  background-color: #999999;
}

.buttonV1.rounded {
  border-radius: 31.5px;
  border: none;
}

section {
  margin: 30px;
  padding: 30px;
  border: none;
  border-radius: 8px;
}

section > h1,
section > h2,
section > h3 {
  margin: 0;
}

section.warning {
  background-color: #fef48a;
}

/* CSS Transition Group animations */
.customer-enter {
  opacity: 0.01;
}

.customer-enter.customer-enter-active {
  opacity: 1;
  transition: 500ms;
}

.customer-exit {
  opacity: 1;
  transition: opacity 300ms ease, height 400ms ease 300ms;
}

.customer-exit.customer-exit-active {
  opacity: 0.01;
  height: 0px;
}

.navTabs-active path {
  fill: #006db3;
}

// Customizing Ionic components
ion-toolbar {
  --background: #fff;
  contain: none;
}

ion-content {
  --keyboard-offset: 0 !important;
  --ion-background-color: #fff;
  --ion-color-primary: #006db3;
}

ion-input {
  --padding-bottom: 0 !important;
  --padding-end: 0 !important;
  --padding-top: unset !important;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.keyboard-open main {
  // This is to ensure that the content is not hidden behind the keyboard
  transition: all 0.3s ease-in-out;
  height: 60%;
}
