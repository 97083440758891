.progress {
  border-radius: 100px;
  border: 2px solid #1f5099;
  position: relative;
  margin-right: 20px;
  width: 90%;
}

.progressvalue {
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #1f5099;
  height: 20px;
  transition: 0.4s ease;
  transition-delay: 0.4s;
}
