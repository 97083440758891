@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"] {
        --active: #1f5099;
        --active-inner: #fff;
        /* hide default checkbox */
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        vertical-align: middle;
        right: 20px;
        float: left;
        margin-left: 20px;
        position: relative;
        border: 2px solid #1f5099;
        background: var(--b, var(--background));
    }
    .checkbox {
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
    }

    /*  */
    input[type="checkbox"]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
    }
    /* handling inner check */
    input[type="checkbox"]:checked {
        --b: var(--active);
    }

    input[type="checkbox"] {
        height: 24px;
        width: 24px;
        cursor: pointer;
    }

    /* handled  inner checkmark*/
    input[type="checkbox"]:not(.switch):after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        -webkit-transform: rotate(var(--r, 20deg));
        transform: rotate(var(--r, 20deg));
    }
    input[type="checkbox"]:not(.switch):checked {
        --r: 43deg;
    }
}
